import { gql } from "@apollo/client";

export const CHAT_TEMPLATES = gql`
  query chatTemplates($projectId: ID!, $offset: Int, $limit: Int, $filter: ChatTemplateFilterInput) {
    chatTemplates(projectId: $projectId, offset: $offset, limit: $limit, filter: $filter) {
      results {
        id
        name
        description
        shortcutKey
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const CHAT_TEMPLATE = gql`
  query chatTemplate($projectId: ID!, $chatTemplateId: ID!) {
    chatTemplate(projectId: $projectId, chatTemplateId: $chatTemplateId) {
      id
      name
      description
      shortcutKey
      messages {
        type
        value
      }
      createdAt
      updatedAt
    }
  }
`;

export const FREQUENTLY_USED_CHAT_TEMPLATES = gql`
  query frequentlyUsedChatTemplates($projectId: ID!) {
    frequentlyUsedChatTemplates(projectId: $projectId) {
      id
      name
      description
      shortcutKey
    }
  }
`;
