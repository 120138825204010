const login = {
  en: {
    "freeTrialPage.title": "Please continue your sign-up process on a web browser.",
    "freeTrialPage.description1":
      "We’re sorry for the inconvenience as this page is not available on the mobile app. Please continue via",
    "freeTrialPage.link": "console.deeple.ai",
    "freeTrialPage.description2": "on your web browser.",
  },
  th: {
    "freeTrialPage.title": "กรุณาดำเนินการการสมัครบนเว็บเบราว์เซอร์",
    "freeTrialPage.description1":
      "ขออภัยในความไม่สะดวก เนื่องจากหน้านี้ไม่สามารถดำเนินการผ่านแอปพลิเคชันมือถือได้ กรุณาดำเนินการผ่าน",
    "freeTrialPage.link": "console.deeple.ai",
    "freeTrialPage.description2": "บนเว็บเบราว์เซอร์ของคุณ",
  },
};

export default login;
