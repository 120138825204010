import axios from "axios";
import { ApolloError } from "@apollo/client";
import get from "lodash/get";
import _ from "lodash";
import { ENV } from "config";
import { PROJECT } from "constants/Router";
import { Common, Product } from "types";
import { WarningType } from "types/Setting";
import { UserRole } from "types/User";

const SPECIAL_CHARACTER = /[!@#$%^&*(),.?":{}|<>\\]/;
const SPECIAL_CHARACTER_FOR_EMAIL = /[!#$%^&*(),?":{}|<>\\]/;
const TYPE_NAME = "__typename";

export const moveScrollToBottom = () => {
  setTimeout(() => {
    const windowScrollHeight = document.documentElement.scrollHeight;
    document.documentElement.scrollTop = windowScrollHeight;
  }, 200);
};

export function removeTypenameField(value?: Common.Primitive | object): Common.Primitive | object {
  if (value === null || value === undefined) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((v) => removeTypenameField(v));
  }

  /**
   * Using object as string here because of the
   * comparison should be string literal
   */
  if (typeof value === "object") {
    const newObj: { [key: string]: Common.Primitive | object } = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== TYPE_NAME) {
        newObj[key] = removeTypenameField(v);
      }
    });

    return newObj;
  }

  return value;
}

export function removeFieldByName(valueInput: Common.Primitive | object, name: string[]): Common.Primitive | object {
  if (!valueInput) {
    return valueInput;
  }

  if (Array.isArray(valueInput)) {
    return valueInput.map((value) => removeFieldByName(value, name));
  }

  /**
   * Using object as string here because of the
   * comparison should be string literal
   */
  if (typeof valueInput === "object") {
    const newObj: { [key: string]: Common.Primitive | object } = {};
    Object.entries(valueInput).forEach(([key, value]) => {
      if (!name.includes(key)) {
        newObj[key] = removeFieldByName(value, name);
      }
    });

    return newObj;
  }

  return valueInput;
}

export const removeLastSlash = (url: string) => {
  const regex = /\/$/gm;
  return url.replace(regex, "");
};

export const isStrMatch = (mainText: string, text: string) => {
  return new RegExp(mainText, "g").test(text);
};

export const convertPriceFormat = (price: number, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  return price?.toLocaleString?.("en-US", { minimumFractionDigits, maximumFractionDigits });
};

export const parseProjectId = (pathname: string) => {
  const pathnameWithoutLastSlash = removeLastSlash(pathname);
  const paths = pathnameWithoutLastSlash.split("/");
  if (paths.length > 2 && paths[1] === PROJECT) return paths[2];
  return "";
};

export const parsePage = (pathname: string) => {
  const pathnameWithoutLastSlash = removeLastSlash(pathname);
  const paths = pathnameWithoutLastSlash.split("/");

  let currentPage = "/";
  let currentSubRoute = "";

  if (paths.length <= 3 && paths[1]) {
    // eslint-disable-next-line prefer-destructuring
    currentPage = paths[1];
  } else if (paths.length > 3 && paths[1] === PROJECT) {
    // eslint-disable-next-line prefer-destructuring
    currentPage = paths[3];
  }

  if (paths.length > 4 && paths[1] === PROJECT) {
    // eslint-disable-next-line prefer-destructuring
    currentSubRoute = paths[4];
  }

  return { currentPage, currentSubRoute };
};

export const convertProductTypeToText = (productTypes: Product.ProductTypeType[]) =>
  productTypes
    .map((type: Product.ProductTypeType) => {
      return type.value;
    })
    .join(", ");

export const getProductPriceRangeFromProductSKUs = (
  productSKUs: Product.ProductSKUType[],
  defaultPrice: number,
  t: Function,
) => {
  if (!productSKUs) {
    return `${t("Price")} : ${t("THB")}${convertPriceFormat(Number(defaultPrice), 0)}`;
  }
  const Prices = productSKUs.map(({ price }) => price);
  const max = Math.max(...Prices);
  const min = Math.min(...Prices);
  if (!max || !min) {
    return `${t("THB")}${convertPriceFormat(Number(defaultPrice), 0)}`;
  }
  if (max === min) {
    return `${t("THB")}${convertPriceFormat(Number(min), 0)}`;
  }
  return `${t("THB")}${convertPriceFormat(Number(min), 0)} - ${t("THB")}${convertPriceFormat(Number(max), 0)}`;
};

export const getProductSKUsKeyValueFromProductSKUs = (productSKUs: Product.ProductSKUType[]) => {
  const productSKUsGroupData: { key: string; value: string[] }[] = [];
  const productSKUKeysSet = new Set<string>();
  // this step should get unique key from productSKU.productType
  productSKUs.forEach((productSKU) => {
    productSKU.productType.forEach((productType) => {
      productSKUKeysSet.add(productType.key.key);
    });
  });
  // this step will get value from productSKU.productType
  // by compare with productSKUKeysSet {key1,key2,...}
  productSKUKeysSet.forEach((keyItem) => {
    const productTypeData: string[] = [];
    productSKUs.forEach((productSKU) => {
      productSKU.productType.forEach((productType) => {
        if (keyItem === productType.key.key && !productTypeData.includes(productType.value)) {
          productTypeData.push(productType.value);
        }
      });
    });
    productSKUsGroupData.push({ key: keyItem, value: productTypeData });
  });
  // this function will return => productSKUsGroupData = { Color : [Grey, Silver]},{ Size : [12, 13, 15]};
  // or return [] if there is no productSKUKeys
  return productSKUsGroupData;
};

export const getParameterByName = (name: string, url: string): string => {
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results || !results[2]) return "";
  return results[2];
};

export const findDuplicatedObjectInArrayByKey = <T,>(array: T[], key: keyof T) => {
  const values = array.map((value) => value[key]);

  const duplicatedValues = values
    .filter((value, index, sourceArray) => sourceArray.indexOf(value) !== index)
    .filter(Boolean);

  return [...Array.from(new Set(duplicatedValues))];
};

export const downloadFile = async (url: string, fileName: string) => {
  try {
    const response = await axios({
      url,
      method: "GET",
      responseType: "blob", // important
    });

    const newUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = newUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      link.remove();
    }, 300);
  } catch (error) {
    console.error(error);
  }
};

export function convertStringWithCommasToNumber(str: string): number {
  return parseInt(str.replace(",", ""), 10);
}

export const validateSpecialCharacter = (inputValue: string) => {
  const specialCharacterRegExp = new RegExp(SPECIAL_CHARACTER, "g");
  const hasSpecialCharacter = specialCharacterRegExp.test(inputValue);

  return hasSpecialCharacter;
};

export const validateSpecialCharacterForEmail = (inputValue: string) => {
  const specialCharacterRegExp = new RegExp(SPECIAL_CHARACTER_FOR_EMAIL, "g");
  const hasSpecialCharacter = specialCharacterRegExp.test(inputValue);

  return hasSpecialCharacter;
};

export const validateImageUrl = (imageUrl = "") => {
  const isImageUrl = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpe?g|png)/g.test(imageUrl);

  return isImageUrl;
};

export const getGraphQLErrorMessage = (error: ApolloError) => {
  return get(error, "graphQLErrors.0.message", "");
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmptyObjects = (obj: any) => {
  // input
  // test functional
  // mockData = {
  //   outputType: "CODE_AUTO_GENERATED",
  //   settings: {
  //     availability: {
  //       type: "NO_EXPIRY",
  //       yo: {},
  //       settings: {
  //         unit: null,
  //         value: null,
  //         availableAt: null,
  //         expiredAt: null
  //       }
  //     },
  //     test: {},
  //     content: null
  //   },
  //   test: null
  // };

  // expected = {
  //     settings : {
  //       outputType: "CODE_AUTO_GENERATED",
  //       settings: {
  //         availability: {
  //           type: "NO_EXPIRY",
  //           outputType: "CODE_AUTO_GENERATED",
  //         },
  //       },
  //   }
  // }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newValue: any = _(obj)
    .pickBy(_.isObject)
    .mapValues(removeEmptyObjects)
    .omitBy(_.isEmpty)
    .assign(_.omitBy(obj, _.isObject))
    .omitBy(_.isNull)
    .value();

  return newValue;
};

export const convertArrayOfNullToNull = <T extends unknown>(arr?: T[] | null): T[] | null => {
  if (!arr) {
    return null;
  }
  const isValid = arr.every((data: T) => !data);
  return isValid ? null : arr;
};

export const calculatePercentage = (value: number, limit: number): string => {
  return limit > 0 ? ((value / limit) * 100).toFixed(2) : `0`;
};

export const getWarningConfig = (type: WarningType, role: UserRole) => {
  switch (type) {
    case WarningType.CREDIT_CARD_ERROR:
      return {
        title: `BroadcastBarWarning.creditCardError.title`,
        description: `BroadcastBarWarning.creditCardError.${role}.description`,
      };
    case WarningType.WALLET_ERROR:
      return {
        title: `BroadcastBarWarning.walletError.title`,
        description: `BroadcastBarWarning.walletError.${role}.description`,
      };
    case WarningType.BLOCKED_PROJECT:
      return {
        title: `BroadcastBarWarning.blocked.${role}.title`,
        description: `BroadcastBarWarning.blocked.${role}.description`,
      };
    case WarningType.DISABLED_CHOOSE_PACKAGE:
      return {
        title: `BroadcastBarWarning.disabledChoosePackage.title`,
        description: `BroadcastBarWarning.disabledChoosePackage.description`,
      };
    default:
      return {
        title: "BroadcastBarWarning.description1",
        description: '"BroadcastBarWarning.description2"',
      };
  }
};

export const getConsoleDynamicLink = () => {
  switch (ENV) {
    case "development":
      return "https://console.dev.deeple.ai";
    case "staging":
      return "https://console.staging.deeple.ai";
    case "production":
      return "https://console.deeple.ai";
    default:
      return "https://console.dev.deeple.ai";
  }
};

export const getCommentCrawlerContentEmbedLink = (contentPostId: string, facebookPageId: string): string => {
  return `https://www.facebook.com/permalink.php?story_fbid=${contentPostId}&id=${facebookPageId}`;
};
