import React, { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { OptionType, ValueType } from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import get from "lodash/get";

import Button from "components/Button";
import CopyText from "components/CopyText";
import ErrorText from "components/ErrorText";
import Select from "components/Select";
import Grid from "components/Grid";
import Typography from "components/Typography";
import TextField from "components/TextField";

import { WEBHOOK_BASE_URL, WEBVIEW_BASE_URL } from "config";
import {
  PAYMENT_GATEWAY_MERCHANT_ID,
  PAYMENT_GATEWAY_API_KEY,
  PAYMENT_GATEWAY_DESCRIPTION_KEY,
  PAYMENT_GATEWAY_MD5_SECRET_KEY,
  PAYMENT_GATEWAY_TYPE_LIST,
  PAYMENT_GATEWAY_TYPE_KEY,
  PAYMENT_TYPES,
  CHILLPAY_WEBHOOK_PATH,
  CHILLPAY_WEBVIEW_PATH,
  PAYMENT_GATEWAY_CUSTOM_HEADER,
  PAYMENT_GATEWAY_CUSTOM_BODY,
  PAYMENT_GATEWAY_CUSTOM_ICON_URL,
  PAYMENT_GATEWAY_CUSTOM_URL,
} from "constants/Payment";
import { PaymentFormDataType } from "types/Payment";
import { ProjectIdType } from "types/Project";
import { formatPaymentGatewaySecretValue } from "utils/payment";
import useGetProject from "utils/hooks/useGetProject";
import { CopiedTextContainer } from "../styled";
import { paymentGatewaySchema } from "../validateSchema";

type PaymentGatewayFormType = {
  defaultType?: string;
  apiKey?: string;
  md5Secret?: string;
  merchantId?: string;
  description?: string;
  isEditingPaymentGateway: boolean;
  onCancelEditPaymentGateway: () => void;
  onSaveNewPaymentGatewayData: (paymentGatewayFormData: PaymentFormDataType) => void;
  isNewPaymentGateway: boolean;
  customUrl?: string;
  customIconUrl?: string;
  customHeader?: string;
  customBody?: string;
};

const PaymentGatewayForm: FC<PaymentGatewayFormType> = (props) => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { isCurrentProjectEnterprise, refId } = useGetProject(projectId);

  const {
    apiKey,
    md5Secret,
    merchantId,
    description,
    defaultType,
    isEditingPaymentGateway,
    onCancelEditPaymentGateway,
    onSaveNewPaymentGatewayData,
    isNewPaymentGateway,
    customUrl,
    customIconUrl,
    customHeader,
    customBody,
  } = props;
  const [selectedType, setSelectedType] = useState<string | undefined>();

  const { register, errors, handleSubmit, setValue, getValues, trigger } = useForm({
    resolver: yupResolver(paymentGatewaySchema),
  });

  const formValues = getValues();
  const defaultApiKey = isEditingPaymentGateway
    ? formValues[PAYMENT_GATEWAY_API_KEY]
    : formatPaymentGatewaySecretValue(apiKey);
  const defaultSecret = isEditingPaymentGateway
    ? formValues[PAYMENT_GATEWAY_MD5_SECRET_KEY]
    : formatPaymentGatewaySecretValue(md5Secret);
  const defaultMerchantId = isEditingPaymentGateway ? formValues[PAYMENT_GATEWAY_MERCHANT_ID] : merchantId;
  const defaultDescription = isEditingPaymentGateway ? formValues[PAYMENT_GATEWAY_DESCRIPTION_KEY] : description;

  const defaultCustomUrl = isEditingPaymentGateway ? formValues[PAYMENT_GATEWAY_CUSTOM_URL] : customUrl;
  const defaultCustomIconUrl = isEditingPaymentGateway ? formValues[PAYMENT_GATEWAY_CUSTOM_ICON_URL] : customIconUrl;
  const defaultCustomHeader = isEditingPaymentGateway
    ? formValues[PAYMENT_GATEWAY_CUSTOM_HEADER]
    : JSON.stringify(customHeader, undefined, 4);
  const defaultCustomBody = isEditingPaymentGateway
    ? formValues[PAYMENT_GATEWAY_CUSTOM_BODY]
    : JSON.stringify(customBody, undefined, 4);

  const handleChangePaymentGatewayType = (type: string | undefined) => {
    register({ name: PAYMENT_GATEWAY_TYPE_KEY });

    setValue(PAYMENT_GATEWAY_MD5_SECRET_KEY, isEditingPaymentGateway ? "" : defaultSecret);
    setValue(PAYMENT_GATEWAY_API_KEY, isEditingPaymentGateway ? "" : defaultApiKey);
    setValue(PAYMENT_GATEWAY_MERCHANT_ID, isEditingPaymentGateway ? "" : defaultMerchantId);
    setValue(PAYMENT_GATEWAY_DESCRIPTION_KEY, isEditingPaymentGateway ? "" : defaultDescription);
    setValue(PAYMENT_GATEWAY_TYPE_KEY, type);

    setValue(PAYMENT_GATEWAY_CUSTOM_URL, isEditingPaymentGateway ? "" : defaultCustomUrl);
    setValue(PAYMENT_GATEWAY_CUSTOM_ICON_URL, isEditingPaymentGateway ? "" : defaultCustomIconUrl);
    setValue(PAYMENT_GATEWAY_CUSTOM_HEADER, isEditingPaymentGateway ? "" : defaultCustomHeader);
    setValue(PAYMENT_GATEWAY_CUSTOM_BODY, isEditingPaymentGateway ? "" : defaultCustomBody);

    setSelectedType(type);

    if (type) {
      trigger();
    }
  };

  const handleChangeOption = (option: ValueType<OptionType>) => {
    const optionValue = (option as OptionType).value;
    handleChangePaymentGatewayType(optionValue);
  };

  const supportedPaymentGateway = isCurrentProjectEnterprise
    ? PAYMENT_GATEWAY_TYPE_LIST
    : PAYMENT_GATEWAY_TYPE_LIST.filter(
        (paymentGatewayType: PAYMENT_TYPES) => paymentGatewayType !== PAYMENT_TYPES.PAYMENT_GATEWAY_CUSTOM,
      );
  const paymentGatewayTypeOptions: OptionType[] = supportedPaymentGateway.map((paymentGatewayType: PAYMENT_TYPES) => ({
    label: paymentGatewayType,
    value: paymentGatewayType,
  }));

  const formatOptionLabel = ({ value }: OptionType): ReactNode => {
    return (
      <div className="w-100 d-flex flex-row align-items-center">
        <div className="ellipsis">{t(value)}</div>
      </div>
    );
  };

  const focusedType = selectedType || defaultType;
  const defaultTypeOption = focusedType
    ? paymentGatewayTypeOptions.find(({ value }: OptionType) => value === focusedType)
    : null;

  const handleClickConfirmButton = (formData: PaymentFormDataType) => {
    onSaveNewPaymentGatewayData(formData);
    onCancelEditPaymentGateway();
  };

  useEffect(() => {
    handleChangePaymentGatewayType(defaultType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingPaymentGateway, isNewPaymentGateway]);

  const chillpayBackGroundUrl = WEBHOOK_BASE_URL ? `${WEBHOOK_BASE_URL}${CHILLPAY_WEBHOOK_PATH}${refId}` : "";
  const chillpayResultUrl = WEBVIEW_BASE_URL ? `${WEBVIEW_BASE_URL}${CHILLPAY_WEBVIEW_PATH}` : "";
  const isChillpayPaymentGateway = focusedType === PAYMENT_TYPES.PAYMENT_GATEWAY_CHILL_PAY;

  return (
    <Grid container>
      {isChillpayPaymentGateway && chillpayBackGroundUrl && chillpayResultUrl && (
        <>
          <Grid item container className="pt-2 pl-1">
            <Grid item container>
              <Grid item>
                <Typography variant="body3" className="pr-2">
                  Background url:
                </Typography>
              </Grid>
            </Grid>
            <Grid item className="pt-1">
              <Typography variant="body3" color="darkMed">
                <CopiedTextContainer>
                  <span className="mr-1">{chillpayBackGroundUrl}</span>
                  <CopyText value={chillpayBackGroundUrl}>{t("Copy")}</CopyText>
                </CopiedTextContainer>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container className="pt-2 pl-1 mb-2">
            <Grid item container>
              <Grid item>
                <Typography variant="body3" className="pr-2">
                  Result url:
                </Typography>
              </Grid>
            </Grid>
            <Grid item className="pt-1">
              <Typography variant="body3" color="darkMed">
                <CopiedTextContainer>
                  <span className="mr-1">{chillpayResultUrl}</span>
                  <CopyText value={chillpayResultUrl}>{t("Copy")}</CopyText>
                </CopiedTextContainer>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("Payment gateway")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            value={defaultTypeOption}
            options={paymentGatewayTypeOptions}
            formatOptionLabel={formatOptionLabel}
            placeholder={t("paymentGateway.placeholder.typeOption")}
            fullWidth
            onChange={handleChangeOption}
            isSearchable={false}
            isDisabled={!isEditingPaymentGateway}
          />
        </Grid>
        {errors[PAYMENT_GATEWAY_TYPE_KEY] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[PAYMENT_GATEWAY_TYPE_KEY], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      {selectedType &&
        (selectedType === PAYMENT_TYPES.PAYMENT_GATEWAY_CUSTOM ? (
          <>
            <Grid item container xs={12} className="pt-2">
              <Grid item className="p-1">
                <Typography variant="body3" color="darkMed">
                  {t("paymentGateway.label.url")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder={t("paymentGateway.label.url")}
                  required
                  fullWidth
                  name={PAYMENT_GATEWAY_CUSTOM_URL}
                  validate={register}
                  defaultValue={defaultCustomUrl}
                  InputProps={!isEditingPaymentGateway ? { className: "Mui-disabled" } : {}}
                  inputProps={{ readOnly: !isEditingPaymentGateway }}
                />
              </Grid>
              {errors[PAYMENT_GATEWAY_CUSTOM_URL] && (
                <Grid item xs={12} className="p-1">
                  <ErrorText>{t(get(errors[PAYMENT_GATEWAY_CUSTOM_URL], "message", ""))}</ErrorText>
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12} className="pt-2">
              <Grid item className="p-1">
                <Typography variant="body3" color="darkMed">
                  {t("paymentGateway.label.header")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder={t("paymentGateway.label.header")}
                  fullWidth
                  name={PAYMENT_GATEWAY_CUSTOM_HEADER}
                  validate={register}
                  defaultValue={defaultCustomHeader}
                  InputProps={!isEditingPaymentGateway ? { className: "Mui-disabled" } : {}}
                  inputProps={{ readOnly: !isEditingPaymentGateway }}
                  rows={3}
                  multiline
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} className="pt-2">
              <Grid item container xs={12} className="p-1">
                <Grid item>
                  <Typography variant="body3" color="darkMed">
                    {t("paymentGateway.label.body")}
                  </Typography>
                </Grid>
                <Grid item className="pl-2">
                  <a href="https://open-api-doc.deeple.ai/docs/enterprise/payment/structure#payment-gateway-body-optional">
                    {/* TO DO: link url to show more information about body */}
                    <Typography variant="body3" color="darkMed">
                      {t("paymentGateway.label.body.description")}
                    </Typography>
                  </a>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder={t("paymentGateway.label.body")}
                  fullWidth
                  name={PAYMENT_GATEWAY_CUSTOM_BODY}
                  validate={register}
                  defaultValue={defaultSecret}
                  InputProps={!isEditingPaymentGateway ? { className: "Mui-disabled" } : {}}
                  inputProps={{ readOnly: !isEditingPaymentGateway }}
                  rows={3}
                  multiline
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} className="pt-2">
              <Grid item className="p-1">
                <Typography variant="body3" color="darkMed">
                  {t("paymentGateway.label.iconUrl")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder={t("paymentGateway.label.iconUrl")}
                  fullWidth
                  name={PAYMENT_GATEWAY_CUSTOM_ICON_URL}
                  validate={register}
                  defaultValue={defaultCustomIconUrl}
                  InputProps={!isEditingPaymentGateway ? { className: "Mui-disabled" } : {}}
                  inputProps={{ readOnly: !isEditingPaymentGateway }}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item container xs={12} className="pt-2">
              <Grid item className="p-1">
                <Typography variant="body3" color="darkMed">
                  {t("Merchant ID")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder={t("Merchant ID")}
                  required
                  fullWidth
                  name={PAYMENT_GATEWAY_MERCHANT_ID}
                  validate={register}
                  defaultValue={defaultMerchantId}
                  InputProps={!isEditingPaymentGateway ? { className: "Mui-disabled" } : {}}
                  inputProps={{ readOnly: !isEditingPaymentGateway }}
                />
              </Grid>
              {errors[PAYMENT_GATEWAY_MERCHANT_ID] && (
                <Grid item xs={12} className="p-1">
                  <ErrorText>{t(get(errors[PAYMENT_GATEWAY_MERCHANT_ID], "message", ""))}</ErrorText>
                </Grid>
              )}
            </Grid>
            {selectedType === PAYMENT_TYPES.PAYMENT_GATEWAY_CHILL_PAY && (
              <Grid item container xs={12} className="pt-2">
                <Grid item className="p-1">
                  <Typography variant="body3" color="darkMed">
                    {t("API key")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    placeholder={t("API key")}
                    required
                    fullWidth
                    name={PAYMENT_GATEWAY_API_KEY}
                    validate={register}
                    defaultValue={defaultApiKey}
                    InputProps={!isEditingPaymentGateway ? { className: "Mui-disabled" } : {}}
                    inputProps={{ readOnly: !isEditingPaymentGateway }}
                  />
                </Grid>
                {errors[PAYMENT_GATEWAY_API_KEY] && (
                  <Grid item xs={12} className="p-1">
                    <ErrorText>{t(get(errors[PAYMENT_GATEWAY_API_KEY], "message", ""))}</ErrorText>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item container xs={12} className="pt-2">
              <Grid item className="p-1">
                <Typography variant="body3" color="darkMed">
                  {t(`paymentGateway.label.secret.${selectedType.toLowerCase()}`)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder={t(`paymentGateway.label.secret.${selectedType.toLowerCase()}`)}
                  required
                  fullWidth
                  name={PAYMENT_GATEWAY_MD5_SECRET_KEY}
                  validate={register}
                  defaultValue={defaultSecret}
                  InputProps={!isEditingPaymentGateway ? { className: "Mui-disabled" } : {}}
                  inputProps={{ readOnly: !isEditingPaymentGateway }}
                />
              </Grid>
              {errors[PAYMENT_GATEWAY_MD5_SECRET_KEY] && (
                <Grid item xs={12} className="p-1">
                  <ErrorText>{t(get(errors[PAYMENT_GATEWAY_MD5_SECRET_KEY], "message", ""))}</ErrorText>
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12} className="pt-2">
              <Grid item className="p-1">
                <Typography variant="body3" color="darkMed">
                  {t("paymentGateway.label.description")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder={t("paymentGateway.label.description")}
                  fullWidth
                  name={PAYMENT_GATEWAY_DESCRIPTION_KEY}
                  validate={register}
                  defaultValue={defaultDescription}
                  InputProps={!isEditingPaymentGateway ? { className: "Mui-disabled" } : {}}
                  inputProps={{ readOnly: !isEditingPaymentGateway }}
                />
              </Grid>
            </Grid>
          </>
        ))}
      {isEditingPaymentGateway && (
        <>
          {/* save button section */}
          <Grid item container spacing={1} xs={12} className="pt-4">
            <Grid item xs={6}>
              <Button size="small" color="ghost" fullWidth onClick={onCancelEditPaymentGateway}>
                {t("Cancel")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              {/* validate from when confirm */}
              <Button size="small" fullWidth onClick={handleSubmit(handleClickConfirmButton)}>
                {t("Confirm")}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PaymentGatewayForm;
