import { SystemTagType } from "types/Tag";
import { LastMessage } from "../graphql/chat/query";

import { OrderConnectionType } from "./Order";

export enum ContactListType {
  ADMIN = "ADMIN",
  ALL = "ALL",
  BOT_DISCONNECTED = "BOT_DISCONNECTED",
}

export type ContactListFilter = {
  customerName?: string;
  customerPlatforms?: PLATFORM[];
  customerTags?: string[];
  systemTags?: string[];
};

export type AgentType = {
  avatar: string;
  displayName: string;
  email: string;
  isDeactivated: boolean;
};

export type CustomerNoteDetailType = {
  createdAt: string;
  id: string;
  note: string;
  agent: AgentType;
};

type CustomerNoteConnectionType = {
  results: CustomerNoteDetailType[];
  total: number;
};

export type CustomerNoteVariablesType = {
  projectId: string;
  customerId: string;
  pageSize: number;
};

export type CustomerAddressType = {
  customerId?: string;
  customerName?: string;
  firstName: string;
  lastName: string;
  streetAddress?: string;
  streetAddress2?: string;
  district?: string;
  subDistrict?: string;
  province?: string;
  postalCode?: string;
  phoneNumber: string;
  email: string;
  note?: string;
};

export type CustomerType = {
  id: string;
  uuid: string;
  name: string;
  platform: PLATFORM;
  isHumanTakingOver: boolean;
  isDisconnected: boolean;
  isNewClient: boolean;
  status: string;
  pictureUrl: string;
  lastMessage: LastMessage;
  unSeenMessageAfterTakingOver: number;
  address: CustomerAddressType;
  orders: OrderConnectionType;
  notes: CustomerNoteConnectionType;
  tags: CustomerTagsType;
  systemTags: SystemTagType[];
  totalSpending: number;
  firstSeen: string;
  lastSeen: string;
  displayName: string;
  lastMessagedAt?: string;
};

export type CustomerQueryType = {
  customer: CustomerType;
};

export type CustomerConnectionType = {
  total: number;
  results: CustomerType[];
};

export type CustomerConnectionDataType = {
  customers: CustomerConnectionType;
};

export type CustomerConnectionVarsType = {
  projectId: string;
  pageSize?: number;
  cursor?: string;
  limit?: number;
  search?: string;
};

export type ContactList = {
  hasNextPage: boolean;
  customers: CustomerType[];
};

export type ContactListData = {
  contactList: ContactList;
};

export type ContactListVariable = {
  projectId: string;
  pageSize?: number;
  cursor?: string;
  limit?: number;
  search?: string;
  contactListType: ContactListType;
  filter?: ContactListFilter;
  offset?: number;
};

export type CustomerStatusChangedType = {
  customerStatusChanged: CustomerType;
};

export type SearchOfflineCustomerType = {
  customerId: string;
  uuid?: string;
  projectId?: string;
  platform?: string;
  name: string;
  address: CustomerAddressType;
};

export enum PLATFORM {
  ALL = "ALL",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINE = "LINE",
  OFFLINE = "OFFLINE",
}

export enum SEARCH_TYPE {
  NAME = "Name",
  TAGS = "tags",
}

export type CustomerTagsType = {
  results: SystemTagType[];
  total: number;
};

export type CustomerTagVariablesType = {
  projectId: string;
  customerId: string;
  pageSize: number;
};

export type CustomerDeleteTagVariablesType = {
  projectId: string;
  customerTagId: string;
};

export type SuggestTagVariablesType = {
  projectId: string;
  type: string;
};

export type SuggestTagsType = {
  suggestTags: string[];
};

export type TagType = {
  id: string;
  tag: string;
};

export type OptionWithTagType = {
  id: string;
  label: string;
  value: string;
};

export type SortByCustomerField = "profile" | "platform" | "totalSpend";
