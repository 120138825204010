import React from "react";
import styled, { css } from "styled-components";
import MUITextField from "@material-ui/core/TextField";
import { DeepleTextFieldProps } from "./index";

const TextField = styled((props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { validate, normalColor, ...rest } = props;
  return <MUITextField {...rest} />;
})<DeepleTextFieldProps>`
  box-sizing: border-box;

  input,
  textarea {
    line-height: 1;
    color: ${({ theme }) => theme.COLORS.DarkGray};
    font-size: 14px;
  }

  textarea {
    padding-top: 10px;
    line-height: 20px;
  }

  textarea.MuiFilledInput-inputMultiline {
    padding-top: 10px;
  }
  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused,
  .MuiFormLabel-root.MuiFormLabel-filled {
    color: ${({ theme }) => theme.COLORS.DarkLight};
  }

  .MuiInput-underline {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  }
  .MuiInput-underline:hover,
  .MuiInput-underline:after,
  .MuiInput-underline.Mui-focused {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.Primary};
  }
  .MuiInput-underline.Mui-error,
  .MuiInput-underline.Mui-error:after {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.Error};
  }

  .MuiInput-underline.Mui-disabled {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:before {
    border: 0;
  }

  .MuiInputBase-input .MuiOutlinedInput-input,
  .MuiOutlinedInput-input {
    padding: 12px 10px 12px 16px;
  }

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 4px;
    min-height: 48px;
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
    border-radius: 8px;
  }
  :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.COLORS.Primary};
    border-radius: 8px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.COLORS.Primary};
    border-radius: 8px;
  }
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.COLORS.Error};
  }
  .MuiFormLabel-root.Mui-error,
  .Mui-error .MuiInputBase-input {
    color: ${({ theme }) => theme.COLORS.Error};
  }

  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.COLORS.LightBlue};
  }

  .MuiOutlinedInput-root.Mui-disabled {
    background-color: ${({ theme, normalColor }) => (normalColor ? theme.COLORS.White : theme.COLORS.Light)};
    border-radius: 8px;
  }

  .MuiFormLabel-root.Mui-disabled,
  .Mui-disabled .MuiInputBase-input {
    color: ${({ theme, normalColor }) => (normalColor ? theme.COLORS.DarkGray : theme.COLORS.LightBlue)};
  }

  .MuiFilledInput-input {
    padding: 0;
    background-color: ${({ theme, disabled }) => (disabled ? theme.COLORS.LightGray : theme.COLORS.White)};
  }

  .MuiFilledInput-root {
    background: initial;
  }

  .MuiFilledInput-multiline {
    padding: 0px;
  }

  .MuiInputBase-formControl.MuiInputBase-multiline.MuiFilledInput-multiline {
    border-radius: 0;
    max-height: 74px;
    overflow-y: scroll;
    padding: 0 8px 7px 8px;
    align-items: baseline;
  }

  .MuiFilledInput-underline:after,
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:hover:before {
    border: none;
  }

  ${(props) =>
    props.isSuccess &&
    css`
      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
      :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ theme }) => theme.COLORS.Success};
        border-radius: 8px;
      }

      .MuiFormLabel-root,
      .MuiInputBase-input {
        color: ${({ theme }) => theme.COLORS.Success};
      }
    `}

  ${(props) =>
    Boolean(!props.isOutlined) &&
    css`
      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
      :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
      .MuiInputBase-root .MuiOutlinedInput-root .Mui-error,
      .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border: 1px solid transparent;
      }

      .MuiOutlinedInput-adornedEnd,
      .MuiOutlinedInput-adornedStart {
        padding: 0;
      }

      .MuiInputBase-input .MuiOutlinedInput-input,
      .MuiOutlinedInput-input {
        padding: 12px 10px;
      }
    `}

    ${(props) =>
    Boolean(props.isBoldText) &&
    css`
      .MuiFormLabel-root,
      .MuiInputBase-input {
        font-weight: bold;
        font-size: 15px;
      }
    `}
`;

export default TextField;
