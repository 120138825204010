import { gql } from "@apollo/client";

import { MessageSource } from "types/Chat";

interface DataType {
  type: string;
  value: string;
}

export interface LastMessage {
  createdAt: string;
  data: DataType[];
  id: string;
  isDeleted: boolean;
  messageUUID: string;
  source: MessageSource;
}

export const ALL_CUSTOMER = gql`
  query allCustomer($projectId: ID!, $limit: Int, $cursor: String, $page: Int, $pageSize: Int, $search: String) {
    customers(projectId: $projectId, limit: $limit, cursor: $cursor, search: $search) {
      cursor
      totalCount
      customers {
        id
        uuid
        name
        platform
        isHumanTakingOver
        isDisconnected
        status
        pictureUrl
        lastMessage {
          id
          data {
            type
            value
          }
          source
          createdAt
        }
        unSeenMessageAfterTakingOver
        tags(page: $page, pageSize: $pageSize) {
          results {
            id
            projectId
            customerId
            tag
            createdAt
            updatedAt
          }
          total
        }
        systemTags {
          id
          projectId
          customerId
          tag
          type
          metadata {
            broadcastId
            orderId
          }
        }
      }
    }
  }
`;

export const CONTACT_LIST = gql`
  query contactList(
    $projectId: ID!
    $search: String
    $contactListType: ContactListType
    $filter: ContactListFilterInput
    $offset: Int
    $limit: Int
    $page: Int
    $pageSize: Int
  ) {
    contactList(
      projectId: $projectId
      search: $search
      contactListType: $contactListType
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      hasNextPage
      customers {
        id
        uuid
        name
        displayName
        platform
        isHumanTakingOver
        isDisconnected
        status
        pictureUrl
        lastMessage {
          id
          isDeleted
          data {
            type
            value
          }
          source
          createdAt
        }
        unSeenMessageAfterTakingOver
        tags(page: $page, pageSize: $pageSize) {
          results {
            id
            projectId
            customerId
            tag
            createdAt
            updatedAt
          }
          total
        }
        systemTags {
          id
          projectId
          customerId
          tag
          type
          createdAt
          metadata {
            broadcastId
            orderId
          }
        }
      }
    }
  }
`;

export const CUSTOMER = gql`
  query CustomerById($customerId: ID!, $projectId: ID!) {
    customer(customerId: $customerId, projectId: $projectId) {
      id
      uuid
      name
      displayName
      platform
      isDisconnected
      isHumanTakingOver
      status
      pictureUrl
      lastMessage {
        id
        data {
          type
          value
        }
        source
        createdAt
      }
      isNewClient
      unSeenMessageAfterTakingOver
    }
  }
`;

export const CHAT_LOGS = gql`
  query chatlogs($projectId: ID!, $customerId: ID!, $offset: Int) {
    messageLog(projectId: $projectId, customerId: $customerId, offset: $offset) {
      messages {
        id
        source
        data {
          type
          value
        }
        referenceId
        messageUUID
        slug
        sentiment
        customerId
        createdAt
        isDeleted
        platform
        consoleDetail {
          firstname
          lastname
          id
          email
        }
      }
      hasNextPage
    }
  }
`;

export const TOTAL_CONTACT_LIST = gql`
  query totalContactList($projectId: ID!) {
    totalContactList(projectId: $projectId) {
      allContactList
      adminContactList
      disconnectContactList
    }
  }
`;

export const TOTAL_UNSEEN_CHAT_MESSAGES = gql`
  query totalUnseenMessages($projectId: ID!) {
    totalUnseenMessages(projectId: $projectId) {
      totalUnseenAdminChatMessages
      totalUnseenChatMessages
      totalUnseenDisconnectedChatMessages
    }
  }
`;

export const TOTAL_UNSEEN_DISCONNECTED_CHAT_MESSAGES = gql`
  query totalUnseenDisconnectedChatMessages($projectId: ID!) {
    totalUnseenDisconnectedChatMessages(projectId: $projectId)
  }
`;

export const TOTAL_UNSEEN_ADMIN_CHAT_MESSAGES = gql`
  query totalUnseenAdminChatMessages($projectId: ID!) {
    totalUnseenAdminChatMessages(projectId: $projectId)
  }
`;

export const GET_MESSAGE_REPLY = gql`
  query getMessageReply($projectId: ID!, $customerId: ID!, $referenceId: String!, $platform: Platform) {
    messageReply(projectId: $projectId, customerId: $customerId, referenceId: $referenceId, platform: $platform) {
      consoleDetail {
        id
      }
      createdAt
      customerId
      customerPlatformUUID
      data {
        type
        value
      }
      id
      isDeleted
      messageType
      messageUUID
      platform
      sentiment
      slug
      source
      referenceId
    }
  }
`;
