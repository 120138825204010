import React, { FC, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

import useGetProject from "utils/hooks/useGetProject";
import Button from "components/Button";
import Card from "components/Card";
import Grid from "components/Grid";
import Pagination from "components/Pagination";
import Search from "components/Search";
import Typography from "components/Typography";
import StickyPanel from "components/StickyPanel";
import LinearProgress from "components/LinearProgress";
import ExportCSVButton from "components/ExportCSVButton";
import { DOWNLOAD_INVENTORY_SAMPLE_URL } from "config/productInventory";
import { INVENTORIES_QUERY_INTERVAL, DEBOUNCE_TIME } from "constants/Order";
import ImportCodeButton from "domain/Product/ImportCodeButton";
import ProductListItem from "domain/Product/ProductListItem";
import SummaryModal from "domain/ProductInventory/SummaryModal";
import ImportButton from "domain/Product/ImportButton";
import { PRODUCTS } from "graphql/product/query";
import { UPDATE_INVENTORY } from "graphql/inventory/mutation";
import {
  ProductType,
  ProductOutputType,
  ProductInventoryType,
  ProductItemPropsType,
  ProductSKUType,
} from "types/Product";
import { ExportFileType } from "types/SalesReport";
import { MultiStoreInventoryType, ProjectIdType } from "types/Project";
import { Device } from "types/Device";
import useDevice from "utils/hooks/useDevice";
import useToggle from "utils/hooks/useToggle";
import { downloadFile } from "utils/common";
import { notifySuccess, notifyError } from "utils/notify";
import useExportInventories from "./hooks/useExportInventories";
import useImportInventories from "./hooks/useImportInventories";
import InventoryCard from "./InventoryCard";
import ImportMethodSelectorModal from "./ImportMethodSelectorModal";
import { Wrapper } from "./styled";

type SummaryType = {
  productCode: string;
  value: number;
};

type ReadQueryProductConnectionType = {
  products: {
    results: ProductItemPropsType[];
    total: number;
    __typename: string;
  };
};

const LIMIT = 15;

const ProductInventory: FC = () => {
  const { projectId } = useParams<ProjectIdType>();
  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;
  const {
    isOpen: isOpenImportTypeModal,
    handleToggle: handleToggleImportTypeModal,
    handleClose: handleCloseImportTypeModal,
  } = useToggle();
  const [searchWord, setSearchWord] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [inventories, setInventories] = useState<ProductInventoryType[]>([]);
  const [summaries, setSummaries] = useState<SummaryType[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { multiStoreInventoryType } = useGetProject(projectId);
  const isClientStore = multiStoreInventoryType === MultiStoreInventoryType.CHILD;

  const { t } = useTranslation();

  const queryVariable = {
    projectId,
    limit: LIMIT,
    offset: pageNo - 1,
    filter: {
      productName: searchWord,
      // productType: ProductType.SKU, // obsolete
      productTypes: [ProductType.SKU, ProductType.DIGITAL_CONTENT],
      categoryId: null,
      sortBy: null,
    },
  };

  const { data, loading: loadingGetAllProductData, refetch } = useQuery(PRODUCTS, {
    variables: queryVariable,
    pollInterval: INVENTORIES_QUERY_INTERVAL,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const onCompleted = async () => {
    await refetch();
    handleCloseImportTypeModal();
    notifySuccess(t("IMPORT_INVENTORIES_SUCCESS"));
  };

  const {
    loading: isLoadingImportInventories,
    handleImportInventories,
    importInventoryType,
    setImportInventoryType,
  } = useImportInventories(t, projectId, onCompleted);

  const { exportInventories, loading: isLoadingExportInventories } = useExportInventories(projectId);

  const handleExportInventories = (exportFileType: ExportFileType) => {
    if (!projectId) {
      return;
    }

    try {
      exportInventories(exportFileType);
      notifySuccess(t("EXPORT_INVENTORIES_SUCCESS"));
    } catch (error) {
      notifyError(t("Please try again later"));
    }
  };

  const handleClickSampleLink = useCallback(
    () => downloadFile(DOWNLOAD_INVENTORY_SAMPLE_URL, "upload-product-inventories-example.csv"),
    [],
  );
  const [updateInventory] = useMutation(UPDATE_INVENTORY, {
    update(cache, { data: { updateInventory: updatedInventoryData } }) {
      const cachedProducts = cache.readQuery<ReadQueryProductConnectionType>({
        query: PRODUCTS,
        variables: queryVariable,
      });
      if (cachedProducts) {
        const newCacheProductResults = cachedProducts.products.results.map((product: ProductItemPropsType) => {
          const newProductSKUs = product.productSKUs.map((productSKU: ProductSKUType) => {
            const matchedUpdateData = updatedInventoryData.find(
              (item: ProductInventoryType) => item.productSKUId === productSKU.id,
            );
            if (matchedUpdateData) {
              return {
                ...productSKU,
                inventory: productSKU.inventory + matchedUpdateData.value,
              };
            }
            return productSKU;
          });
          return {
            ...product,
            productSKUs: newProductSKUs,
          };
        });

        cache.writeQuery({
          query: PRODUCTS,
          variables: queryVariable,
          data: {
            products: {
              results: newCacheProductResults,
              total: cachedProducts.products.total,
              // eslint-disable-next-line no-underscore-dangle
              __typename: cachedProducts.products.__typename,
            },
          },
        });
      }
    },
  });

  const handleChangePage = (page: number) => {
    if (page < 1) {
      return setPageNo(1);
    }
    return setPageNo(page);
  };

  const handleSearchChange = debounce((value: string) => {
    setPageNo(1);
    setSearchWord(value);
  }, DEBOUNCE_TIME);

  const handleUpdateInventory = () => {
    updateInventory({
      variables: {
        projectId,
        inventories,
      },
    });
    setInventories([]);
    setSummaries([]);
    setIsOpenModal(false);
  };

  const handleChangeStock = (productSKUId: string, productCode: string, value: number) => {
    const newUpdateSKU = {
      productSKUId,
      value,
    };

    const newUpdateSummary = {
      productCode,
      value,
    };

    const nonUpdateInventories = inventories.filter(
      (inventory: ProductInventoryType) => inventory.productSKUId !== productSKUId,
    );
    const nonUpdateSummary = summaries.filter((summary: SummaryType) => summary.productCode !== productCode);

    const newInventories = value ? [...nonUpdateInventories, newUpdateSKU] : [...nonUpdateInventories];
    const newSummary = value ? [...nonUpdateSummary, newUpdateSummary].sort() : [...nonUpdateSummary];
    setInventories(newInventories);
    setSummaries(newSummary);
  };

  const isShowSaveButton = Boolean(summaries.length);

  return (
    <Wrapper item container xs={12}>
      <Grid item xs={12} style={{ height: 4 }}>
        {loadingGetAllProductData && <LinearProgress />}
      </Grid>

      <Grid item container xs={12} className="px-4 py-4">
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="title1" className="Inventory-title">
            {t("Inventory")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} className="mt-3 mt-md-0 d-flex justify-content-start justify-content-md-end">
          {!isClientStore && (
            <>
              <ExportCSVButton
                className="mr-3"
                isLoading={isLoadingExportInventories}
                label={t("EXPORT_INVENTORY")}
                subLabel={t("product.import.button.subLabel")}
                onExport={handleExportInventories}
              />

              <ImportButton
                label={t("IMPORT_INVENTORY")}
                subLabel={t("product.import.button.subLabel")}
                isLoading={isLoadingImportInventories}
                labelLink={t("product.download.sample")}
                onClick={handleToggleImportTypeModal}
                onClickLink={handleClickSampleLink}
              />
            </>
          )}
        </Grid>

        <Grid item container alignItems="flex-end" className="pt-1">
          <Grid item xs={12}>
            <Search
              defaultValue={searchWord}
              onChange={handleSearchChange}
              placeholder={t("searchPlaceholder.productInventory")}
            />
          </Grid>
          {/* TODO: filter button with filter data zone */}
        </Grid>
      </Grid>
      {/* inventory list zone */}
      {data && (
        <Grid item container className={isShowSaveButton ? "pb-4" : ""}>
          {data.products.results.map((product: ProductItemPropsType) => {
            const { id, images, productSKUs, defaultPrice, name, type, outputType } = product;

            const productImage = images?.[0]?.src || "";
            const isDigitalProduct = type === ProductType.DIGITAL_CONTENT;
            const isCodeImportProduct = outputType === ProductOutputType.CODE_IMPORTED;

            return (
              <Card
                padding="0px"
                key={id}
                className={`w-100 my-2 ${isDesktop ? "mx-3" : ""}`}
                borderRadius={isDesktop ? 8 : 0}
              >
                <Grid item container xs={12} className="pt-3">
                  <Grid item container xs={12} className="pl-3 mb-2">
                    <Grid item className="d-flex flex-grow-1">
                      <ProductListItem
                        productImage={productImage}
                        productSKUs={productSKUs}
                        defaultPrice={defaultPrice}
                        productName={name}
                        isActive={
                          !!productSKUs.find((productSKUs: { selected: boolean }) => productSKUs.selected === true)
                        }
                        productImageMaxSize={100}
                        isDigitalProduct={isDigitalProduct}
                      />
                    </Grid>
                    {isDesktop && isCodeImportProduct && (
                      <Grid item className="d-flex flex-grow-0 mr-3" style={{ width: "min-content" }}>
                        <ImportCodeButton projectId={projectId} onCompleted={() => refetch()} />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InventoryCard
                      productSKUs={productSKUs}
                      onChangeStock={handleChangeStock}
                      tempInventories={inventories}
                      // hide inventory only digital code import
                      isDisableUpdateStock={isDigitalProduct && isCodeImportProduct}
                      isClientStore={isClientStore}
                    />
                  </Grid>
                </Grid>
              </Card>
            );
          })}
          <Grid item container className={summaries.length ? "mb-5" : ""}>
            <Pagination
              rowsPerPage={LIMIT}
              page={pageNo}
              totalItem={data.products.total}
              handlePageChange={handleChangePage}
              isMobile
            />
          </Grid>
        </Grid>
      )}
      {isShowSaveButton && (
        <StickyPanel className="p-3">
          <Button fullWidth onClick={() => setIsOpenModal(true)}>
            {t("Save")}
          </Button>
        </StickyPanel>
      )}

      <SummaryModal
        summaries={summaries}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onSubmit={handleUpdateInventory}
        title={t("Update stock record")}
        subTitle={t("Summary")}
      />

      <ImportMethodSelectorModal
        isLoading={isLoadingImportInventories}
        isOpen={isOpenImportTypeModal}
        onClose={handleCloseImportTypeModal}
        onSelect={setImportInventoryType}
        onSubmit={handleImportInventories}
        subTitle={t("IMPORTING_METHOD")}
        title={t("IMPORT_INVENTORY")}
        type={importInventoryType}
      />
    </Wrapper>
  );
};

export default ProductInventory;
