const integration = {
  en: {
    "integration.title": "Integration",
    "inventoryIntegration.confirmationForm.title": "Confirmation",
    "inventoryIntegration.confirmationForm.platform.label": "Platform:",
    "inventoryIntegration.confirmationForm.storeName.label": "Storename:",
    "inventoryIntegration.confirmationForm.warehouse.label": "Warehouse:",
    "inventoryIntegration.confirmationForm.syncingMethod.label": "Syncing method:",
    "inventoryIntegration.confirmationForm.syncedCatalogs.label": "Synced catalogs:",
    "inventoryIntegration.confirmationForm.apiKey.label": "API key",
    "inventoryIntegration.confirmationForm.apiSecret.label": "API secret",
    "inventoryIntegration.syncingMethodForm.title": "Syncing method",
    "inventoryIntegration.syncingMethodForm.warehouse.label": "Warehouse selection",
    "inventoryIntegration.syncingMethodForm.replace.title": "Replace",
    "inventoryIntegration.syncingMethodForm.replace.description":
      "Sync with Zort by replacing the entire inventory on the console with the selected inventory from Zort.",
    "inventoryIntegration.syncingMethodForm.merge.title": "Merge",
    "inventoryIntegration.syncingMethodForm.merge.description":
      "Sync with Zort by merging the existing products on the console with the other products from Zort.",
    "inventoryIntegration.credentialForm.title": "API Connection details",
    "inventoryIntegration.credentialForm.apiKey.label": "API key",
    "inventoryIntegration.credentialForm.apiSecret.label": "API secret",
    "inventoryIntegration.credentialForm.storeName.label": "Storename",
    "inventoryIntegration.credentialForm.apiKey.placeholder": "Please fill API key",
    "inventoryIntegration.credentialForm.apiSecret.placeholder": "Please fill API secret",
    "inventoryIntegration.credentialForm.storeName.placeholder": "Please fill Storename",
    "inventoryIntegration.modal.addInventoryIntegration.title": "Add inventory integration",
    "inventoryIntegration.error.required.apiKey": "Please fill API key",
    "inventoryIntegration.error.required.apiSecret": "Please fill API secret",
    "inventoryIntegration.error.required.storeName": "Please fill Storename",
    "inventoryIntegration.error.required.warehouse": "Please select warehouse",
    "inventoryIntegration.zort.title": "Zort integration",
    "inventoryIntegration.zort.description": "Enable Zort to sync its orders, products and inventory with the console.",
    "inventoryIntegration.button.connect": "Connect",
    "inventoryIntegration.button.disconnect": "Disconnect",
    "inventoryIntegration.error.duplicated.product.name": "Zort product ({{productCodes}}) has duplicated product name",
    "inventoryIntegration.error.incorrect.variant":
      "Zort variant ({{productCode}}) does not have same category, please update variant to have the same category",
    "inventoryIntegration.error.exceeded.variant":
      "Zort variant ({{productCode}}) exceeded variant limit, please update variant to be less than or equals 2 variant types",
    "integration.zort.status.failed": "Failed",
    "integration.zort.status.succeeded": "Succeeded",
    "integration.zort.status.inProgress": "In progress",
    "integration.zort.status": "Status",
    "integration.zort.lastUpdated": "Last updated",
    "inventoryIntegration.generateSecret.title": "Generate Deeple API key for API integration",
    "inventoryIntegration.generateSecret.button": "Generate a new key",
    "inventoryIntegration.generateSecret.confirmation":
      "Are you sure to generate new API SECRET KEY? (If you already had, your exist API SECRET KEY won't be able to use)",
    "integration.generate.success": "Generate secret API key is successful",
    "integration.generate.fail": "Generate secret API key is fail",
    "inventoryIntegration.error.exceed.product":
      "Project does not have enough product quota to sync product from Zortout",
    "inventoryIntegration.error.not.exist": "Zort integration does not exist",
    "integration.zort.resync": "Updated",
    "integration.zort.automatic": "Automatic",
  },
  th: {
    "integration.title": "การเชื่อมต่อข้อมูล",
    "inventoryIntegration.confirmationForm.title": "ยืนยัน",
    "inventoryIntegration.confirmationForm.platform.label": "แพลตฟอร์ม:",
    "inventoryIntegration.confirmationForm.storeName.label": "Storename:",
    "inventoryIntegration.confirmationForm.warehouse.label": "คลังสินค้า:",
    "inventoryIntegration.confirmationForm.syncingMethod.label": "วิธีการเชื่อมต่อ:",
    "inventoryIntegration.confirmationForm.syncedCatalogs.label": "หมวดหมู่สินค้าที่เชื่อมต่อ:",
    "inventoryIntegration.confirmationForm.apiKey.label": "API key",
    "inventoryIntegration.confirmationForm.apiSecret.label": "API secret",
    "inventoryIntegration.syncingMethodForm.title": "วิธีการเชื่อมต่อ",
    "inventoryIntegration.syncingMethodForm.warehouse.label": "เลือกคลังสินค้า",
    "inventoryIntegration.syncingMethodForm.replace.title": "Replace",
    "inventoryIntegration.syncingMethodForm.replace.description":
      "เชื่อมต่อกับ Zort โดยแทนที่สินค้าคงคลังทั้งหมดบนคอนโซลด้วยสินค้าคงคลังที่เลือกจาก Zort",
    "inventoryIntegration.syncingMethodForm.merge.title": "Merge",
    "inventoryIntegration.syncingMethodForm.merge.description":
      "เชื่อมต่อกับ Zort โดยการรวมสินค้าคงคลังที่มีอยู่บนคอนโซลกับสินค้าคงคลัง จาก Zort",
    "inventoryIntegration.credentialForm.title": "ข้อมูลการเชื่อมต่อ API",
    "inventoryIntegration.credentialForm.apiKey.label": "API key",
    "inventoryIntegration.credentialForm.apiSecret.label": "API secret",
    "inventoryIntegration.credentialForm.storeName.label": "Storename",
    "inventoryIntegration.credentialForm.apiKey.placeholder": "โปรดระบุ API key",
    "inventoryIntegration.credentialForm.apiSecret.placeholder": "โปรดระบุ API secret",
    "inventoryIntegration.credentialForm.storeName.placeholder": "โปรดระบุ Storename",
    "inventoryIntegration.modal.addInventoryIntegration.title": "เพิ่ม inventory integration",
    "inventoryIntegration.error.required.apiKey": "ระบุ API key",
    "inventoryIntegration.error.required.apiSecret": "ระบุ API secret",
    "inventoryIntegration.error.required.storeName": "ระบุ Storename",
    "inventoryIntegration.error.required.warehouse": "กรุณาเลือกคลังสินค้า",
    "inventoryIntegration.zort.title": "การเชื่อมต่อกับ Zort",
    "inventoryIntegration.zort.description": "เปิดใช้งาน Zort เพื่อเชื่อมต่อคำสั่งซื้อ, สินค้าและคลังสินค้า กับคอนโซล",
    "inventoryIntegration.button.connect": "เชื่อมต่อ",
    "inventoryIntegration.button.disconnect": "ยกเลิกการเชื่อมต่อ",
    "inventoryIntegration.error.duplicated.product.name": "ข้อมูลสินค้าของ ​Zort ({{productCodes}}) มีชื่อสินค้าซ้ำกัน",
    "inventoryIntegration.error.incorrect.variant":
      "สินค้าหลายคุณสมบัติ ({{productCode}}) ไม่ได้อยู่ในหมวดหมู่เดียวกันทั้งหมด กรุณาแก้ไขให้สินค้าหลายคุณสมบัติอยู่ในหมวดหมู่เดียวกัน",
    "inventoryIntegration.error.exceeded.variant":
      "สินค้าหลายคุณสมบัติ ({{productCode}}) มีจำนวนคุณสมบัติมากกว่าที่ระบบรองรับ กรุณาแก้ไขให้จำนวนคุณสมบัตืให้น้อยกว่าหรือเท่ากับ 2 คุณสมบัติ",
    "integration.zort.status.failed": "ล้มเหลว",
    "integration.zort.status.succeeded": "สำเร็จ",
    "integration.zort.status.inProgress": "กำลังดำเนินการ",
    "integration.zort.status": "สถานะ",
    "integration.zort.lastUpdated": "อัพเดทล่าสุด",
    "inventoryIntegration.generateSecret.title": "สร้าง Deeple API key สำหรับการเชื่อมต่อกับ API",
    "inventoryIntegration.generateSecret.button": "สร้างใหม่",
    "inventoryIntegration.generateSecret.confirmation":
      "คุณต้องการที่จะสร้าง API SECRET KEY ใหม่หรือไม่ (ถ้ามี API SECRET KEY อยู่แล้ว ตัวเก่าจะใช้งานไม่ได้)",
    "integration.generate.success": "สร้าง secret API key สำเร็จ",
    "integration.generate.fail": "สร้าง secret API key ล้มเหลว",
    "inventoryIntegration.error.exceed.product":
      "ระบบไม่สามารถดึงข้อมูลสินค้าจาก Zortout มาได้เนื่องจากเกินจำนวนโควต้าสินค้าที่ร้านมี",
    "inventoryIntegration.error.not.exist": "ไม่สามารถอัพเดทข้อมูล สินค้าจาก Zort ได้",
    "integration.zort.resync": "อัพเดท",
    "integration.zort.automatic": "อัตโนมัติ",
  },
};

export default integration;
