import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import get from "lodash/get";

import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import TextField from "components/TextField";
import Typography from "components/Typography";
import ImageUpload from "components/ImageUpload";

import {
  PROMPT_PAY_ACCOUNT_NAME_KEY,
  PROMPT_PAY_ACCOUNT_NUMBER_KEY,
  PROMPT_PAY_CUSTOM_QR_IMAGE,
} from "constants/Payment";
import { ImageUrl } from "types/Image";

type PromptPayFormType = {
  accountName?: string;
  accountNumber?: string;
  image?: string;
  setValue: (
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    config?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void;
  showSaveButton: () => void;
};

const PromptPayForm: FC<PromptPayFormType> = (props) => {
  const { t } = useTranslation();
  const { register, errors } = useFormContext();
  const { accountName, accountNumber, image: imageProp, setValue, showSaveButton } = props;
  const [image, setImage] = useState<string | undefined>(imageProp);

  const handleChangeImage = (imageUrls: ImageUrl[]) => {
    if (imageUrls.length) {
      const [{ publicUrl }] = imageUrls;

      setImage(publicUrl);
      setValue(PROMPT_PAY_CUSTOM_QR_IMAGE, publicUrl);
      showSaveButton();
    }
  };

  const handleRemoveImage = () => {
    setValue(PROMPT_PAY_CUSTOM_QR_IMAGE, undefined);
    showSaveButton();
  };

  return (
    <Grid container>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("PromptPay name")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            placeholder={t("PromptPay name")}
            required
            fullWidth
            name={PROMPT_PAY_ACCOUNT_NAME_KEY}
            validate={register}
            defaultValue={accountName}
            onChange={showSaveButton}
          />
        </Grid>
        {errors[PROMPT_PAY_ACCOUNT_NAME_KEY] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[PROMPT_PAY_ACCOUNT_NAME_KEY], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("PromptPay number")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="tel"
            placeholder={t("PromptPay number")}
            required
            fullWidth
            name={PROMPT_PAY_ACCOUNT_NUMBER_KEY}
            validate={register}
            defaultValue={accountNumber}
            onChange={showSaveButton}
          />
        </Grid>
        {errors[PROMPT_PAY_ACCOUNT_NUMBER_KEY] && (
          <Grid item xs={12} className="p-1">
            <ErrorText>{t(get(errors[PROMPT_PAY_ACCOUNT_NUMBER_KEY], "message", ""))}</ErrorText>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} className="pt-2">
        <Grid item className="p-1">
          <Typography variant="body3" color="darkMed">
            {t("PromptPay image")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ImageUpload onChange={handleChangeImage} onRemove={handleRemoveImage} image={image || ""} isConfirmRemove />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PromptPayForm;
